var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Layanan ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Arsitektur Domain Layanan SPBE.")])]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Arsitektur Domain Layanan sesuai dengan Renja'),expression:"'Arsitektur Domain Layanan sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label-for":"vi-master_layanan"}},[_c('validation-provider',{attrs:{"name":"Pilih Nama Layanan atau Tambah Baru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_layanan","required":"","name":"master_layanan","options":_vm.referenceData.services_data_list,"placeholder":"Pilih Nama Layanan atau Tambah Baru","label":"label"},on:{"input":_vm.setSelected},model:{value:(_vm.master_layanan),callback:function ($$v) {_vm.master_layanan=$$v},expression:"master_layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.master_layanan !== '')?_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Domain Layanan","label-for":"vi-service_domain_id"}},[_c('validation-provider',{attrs:{"name":"Domain Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-service_domain_id","required":"","name":"service_domain_id","options":_vm.referenceData.ref_domain_layanan,"placeholder":"Domain Layanan","label":"nama"},on:{"input":_vm.getAreaLayanan},model:{value:(_vm.service_domain_id),callback:function ($$v) {_vm.service_domain_id=$$v},expression:"service_domain_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1345705165)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Area Layanan","label-for":"vi-service_area_id"}},[_c('validation-provider',{attrs:{"name":"Area Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-service_area_id","required":"","name":"service_area_id","options":_vm.daftarAreaLayanan,"placeholder":"Area Layanan","label":"nama"},on:{"input":_vm.getKatLayanan},model:{value:(_vm.service_area_id),callback:function ($$v) {_vm.service_area_id=$$v},expression:"service_area_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3938250451)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kategori Layanan","label-for":"vi-service_category"}},[_c('validation-provider',{attrs:{"name":"Kategori Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.fungsi_and_sub)?_c('v-select',{attrs:{"id":"vi-service_category","required":"","name":"service_category","options":_vm.daftarKatLayanan,"placeholder":"Kategori Layanan","label":"nama"},on:{"input":_vm.getSubKatLayanan},model:{value:(_vm.service_category),callback:function ($$v) {_vm.service_category=$$v},expression:"service_category"}}):_vm._e(),(_vm.fungsi_and_sub)?_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-service_category","required":"","name":"service_category","placeholder":"Kategori Layanan"},model:{value:(_vm.kategori_layanan_nama),callback:function ($$v) {_vm.kategori_layanan_nama=$$v},expression:"kategori_layanan_nama"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('cat')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,715550035)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sub Kategori Layanan","label-for":"vi-service_sub_category"}},[_c('validation-provider',{attrs:{"name":"Sub Kategori Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.sub_fungsi)?_c('v-select',{attrs:{"id":"vi-service_sub_category","required":"","name":"service_sub_category","options":_vm.daftarSubKatLayanan,"placeholder":"Sub Kategori Layanan","label":"nama"},on:{"input":_vm.setSubKatLayanan},model:{value:(_vm.service_sub_category),callback:function ($$v) {_vm.service_sub_category=$$v},expression:"service_sub_category"}}):_vm._e(),(_vm.sub_fungsi)?_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-service_sub_category","required":"","name":"service_sub_category","placeholder":"Sub Kategori Layanan"},model:{value:(_vm.sub_kategori_layanan_nama),callback:function ($$v) {_vm.sub_kategori_layanan_nama=$$v},expression:"sub_kategori_layanan_nama"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.unitReset('sub')}}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1839175537)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan nama dari layanan yang dihasilkan oleh unit kerja/perangkat daerah.'),expression:"'Merupakan nama dari layanan yang dihasilkan oleh unit kerja/perangkat daerah.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Layanan","label-for":"vi-nama_layanan"}},[_c('validation-provider',{attrs:{"name":"Nama Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_layanan","required":"","name":"nama_layanan","placeholder":"Nama Layanan"},model:{value:(_vm.nama_layanan),callback:function ($$v) {_vm.nama_layanan=$$v},expression:"nama_layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3920977258)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan tujuan dari layanan.'),expression:"'Merupakan penjelasan tujuan dari layanan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Tujuan Layanan","label-for":"vi-tujuan_layanan"}},[_c('validation-provider',{attrs:{"name":"Tujuan Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-tujuan_layanan","required":"","name":"tujuan_layanan","placeholder":"Tujuan Layanan"},model:{value:(_vm.tujuan_layanan),callback:function ($$v) {_vm.tujuan_layanan=$$v},expression:"tujuan_layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2022983850)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan uraian penjelasan dari fungsi-fungsi yang terdapat dalam layanan.'),expression:"'Merupakan uraian penjelasan dari fungsi-fungsi yang terdapat dalam layanan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Fungsi Layanan","label-for":"vi-fungsi_layanan"}},[_c('validation-provider',{attrs:{"name":"Fungsi Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-fungsi_layanan","required":"","name":"fungsi_layanan","placeholder":"Fungsi Layanan"},model:{value:(_vm.fungsi_layanan),callback:function ($$v) {_vm.fungsi_layanan=$$v},expression:"fungsi_layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2767402186)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan unit kerja/perangkat daerah yang melaksanakan layanan.'),expression:"'Merupakan unit kerja/perangkat daerah yang melaksanakan layanan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pelaksana","label-for":"vi-unit_pelaksana"}},[_c('validation-provider',{attrs:{"name":"Unit Pelaksana","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_pelaksana","required":"","name":"unit_pelaksana","placeholder":"Unit Pelaksana"},model:{value:(_vm.unit_pelaksana),callback:function ($$v) {_vm.unit_pelaksana=$$v},expression:"unit_pelaksana"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,892589322)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kementerian/lembaga terkait dengan layanan.'),expression:"'Merupakan kementerian/lembaga terkait dengan layanan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Pemda / Kementrian / Lembaga terkait","label-for":"vi-data_instansi_id"}},[_c('validation-provider',{attrs:{"name":"Pemda / Kementrian / Lembaga terkait","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_instansi_id","required":"","multiple":"","name":"data_instansi_id","options":_vm.referenceData.ref_instansi,"placeholder":"Pemda / Kementrian / Lembaga terkait","label":"name"},model:{value:(_vm.data_instansi_id),callback:function ($$v) {_vm.data_instansi_id=$$v},expression:"data_instansi_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1813077141)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan target pengguna layanan.'),expression:"'Merupakan target pengguna layanan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Target Layanan","label-for":"vi-target_layanan"}},[_c('validation-provider',{attrs:{"name":"Target Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-target_layanan","required":"","name":"target_layanan","placeholder":"Target Layanan"},model:{value:(_vm.target_layanan),callback:function ($$v) {_vm.target_layanan=$$v},expression:"target_layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,405398698)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan model dari layanan.'),expression:"'Merupakan model dari layanan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Metode Layanan","label-for":"vi-metode_layanan"}},[_c('validation-provider',{attrs:{"name":"Metode Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-metode_layanan","rows":"4","required":"","name":"metode_layanan","placeholder":"Metode Layanan"},model:{value:(_vm.metode_layanan),callback:function ($$v) {_vm.metode_layanan=$$v},expression:"metode_layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2426076493)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }