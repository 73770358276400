<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Data &amp; Informasi
        </h5>
        <small class="text-muted">Arsitektur Domain Data dan Informasi SPBE.</small>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Data dan Informasi sesuai dengan Renja'"
          label-for="vi-data_utama_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Data dan Informasi atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_data"
              v-model="master_data"
              required
              name="master_data"
              :options="referenceData.data_informasi_list"
              placeholder="Pilih Nama Data dan Informasi atau Tambah Baru"
              label="label"
              @input="setSelected()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="master_data !== ''">
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
          label="Data Pokok"
          label-for="vi-data_basic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Pokok"
            rules="required"
          >
            <v-select
              id="vi-data_basic_id"
              v-model="data_basic_id"
              required
              name="data_basic_id"
              :options="referenceData.ref_data_pokok"
              placeholder="Data Pokok"
              label="nama"
              @input="getDataTematik"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
          label="Data Tematik"
          label-for="vi-data_tematic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Tematik"
            rules="required"
          >
            <v-select
              id="vi-data_tematic_id"
              v-model="data_tematic_id"
              required
              name="data_tematic_id"
              :options="daftarDataTematik"
              placeholder="Data Tematik"
              label="nama"
              @input="getDataTopik"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
          label="Data Topik"
          label-for="vi-data_topic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Topik"
            rules="required"
          >
            <v-select
              v-if="!fungsi_and_sub"
              id="vi-data_topic_id"
              v-model="data_topic_id"
              required
              name="data_topic_id"
              :options="daftarDataTopik"
              placeholder="Data Topik"
              label="nama"
              @input="getDataSubTopik"
            />
            <b-input-group
              v-if="fungsi_and_sub"
            >
              <b-form-input
                v-if="fungsi_and_sub"
                id="vi-data_topic_id"
                v-model="data_topic_nama"
                required
                name="data_topic_id"
                placeholder="Data Topik"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset('cat')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan pilihan kode model referensi data yang sesuai dengan data yang dipilih'"
          label="Data Sub Topik"
          label-for="vi-sub_data_topic_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Sub Topik"
            rules="required"
          >
            <v-select
              v-if="!sub_fungsi"
              id="vi-sub_data_topic_id"
              v-model="sub_data_topic_id"
              required
              name="sub_data_topic_id"
              :options="daftarDataSubTopik"
              placeholder="Data Sub Topik"
              label="nama"
              @input="setSubFungsiGov"
            />
            <b-input-group
              v-if="sub_fungsi"
            >
              <b-form-input
                v-if="sub_fungsi"
                id="vi-sub_data_topic_id"
                v-model="sub_data_topic_nama"
                required
                name="sub_data_topic_id"
                placeholder="Data Sub Topik"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset('sub')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan data yang dikelola oleh unit kerja/perangkat daerah.'"
          label="Nama Data"
          label-for="vi-nama"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Data"
            rules="required"
          >
            <b-form-input
              id="vi-nama"
              v-model="nama"
              required
              name="nama"
              placeholder="Nama Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan apakah terdapat interoperabilitas data dengan data yang lain.'"
          label="Interoperabilitas Data"
          label-for="vi-data_interoperability_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Interoperabilitas Data"
            rules="required"
          >
            <v-select
              id="vi-data_interoperability_id"
              v-model="data_interoperability_id"
              required
              name="data_interoperability_id"
              :options="daftarDataInteroperability"
              placeholder="Interoperabilitas Data"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
          label="Sifat Data"
          label-for="vi-data_status_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Sifat Data"
            rules="required"
          >
            <v-select
              id="vi-data_status_id"
              v-model="data_status_id"
              required
              name="data_status_id"
              :options="referenceData.ref_sifat_data"
              placeholder="Sifat Data"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari pemanfaatan data.'"
          label="Tujuan Data"
          label-for="vi-tujuan"
        >
          <validation-provider
            #default="{ errors }"
            name="Tujuan Data"
            rules="required"
          >
            <b-form-textarea
              id="vi-tujuan"
              v-model="tujuan"
              rows="4"
              required
              name="tujuan"
              placeholder="Tujuan Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.left="'Merupakan tanggal mulai berlakunya data.'"
          label="Awal Validitas Data"
          label-for="vi-valid_start"
        >
          <validation-provider
            #default="{ errors }"
            name="Awal Validitas Data"
            rules="required"
          >
            <b-form-datepicker
              id="vi-valid_start"
              v-model="valid_start"
              :max="valid_end"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              size="sm"
              required
              name="valid_start"
              placeholder="Awal Validitas Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-b-tooltip.hover.left="'Merupakan tanggal akhir berlakunya data.'"
          label="Akhir Validitas Data"
          label-for="vi-valid_end"
        >
          <validation-provider
            #default="{ errors }"
            name="Akhir Validitas Data"
            rules="required"
          >
            <b-form-datepicker
              id="vi-valid_end"
              v-model="valid_end"
              :min="valid_start"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              size="sm"
              required
              name="valid_end"
              placeholder="Akhir Validitas Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penanggung jawab dari data.'"
          label="Penanggung Jawab"
          label-for="vi-pic_name"
        >
          <validation-provider
            #default="{ errors }"
            name="Penanggung Jawab"
            rules="required"
          >
            <b-form-input
              id="vi-pic_name"
              v-model="pic_name"
              required
              name="pic_name"
              placeholder="Penanggung Jawab"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'"
          label="Uraian Data"
          label-for="vi-deskripsi"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Data"
            rules="required"
          >
            <b-form-textarea
              id="vi-deskripsi"
              v-model="deskripsi"
              rows="4"
              required
              name="deskripsi"
              placeholder="Uraian Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan wali dari data.'"
          label="Wali Data"
          label-for="vi-walidata"
        >
          <validation-provider
            #default="{ errors }"
            name="Wali Data"
            rules="required"
          >
            <b-form-input
              id="vi-walidata"
              v-model="walidata"
              required
              name="walidata"
              placeholder="Wali Data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="master_data !== '' && (jenis_pengadaan_id == '3' || jenis_pengadaan_id == '6')">
      <b-row
        class="mb-1"
      >
        <b-col>
          <p>Dokumen Rujukan</p>
          <b-list-group flush>
            <b-list-group-item
              v-for="(key, idx) in referenceData.ref_document_rujukan_data"
              :key="idx"
            >
              <b-form-checkbox
                :ref="`tipe-${idx}`"
                v-model="rujukan_select"
                :value="key.nama"
                plain
                class="pl-2"
                @change="setRujukan"
              >
                {{ key.nama }}
              </b-form-checkbox>
              <template v-if="key.nama !== 'Lainnya' && rujukan_select.includes(key.nama)">
                <div
                  v-if="key.childreen.length > 2"
                  class="ml-2 my-50"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`Dokumen Rujukan ${key.nama}`"
                    rules="required"
                  >
                    <v-select
                      :id="`vi-indikator_rujukan-${idx}`"
                      v-model="rujukan_select_dtl[key.nama]"
                      required
                      multiple
                      name="indikator_rujukan"
                      :options="key.childreen"
                      placeholder="Pilih Nama Data atau Indikator"
                      label="nama"
                      @input="setRujukan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </template>
              <template v-if="key.nama === 'Lainnya' && rujukan_select.includes(key.nama)">
                <div class="ml-2 my-50">
                  <b-form-group
                    label="Regulasi"
                    label-for="regulasi"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Dokumen Rujukan Lainnya"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-regulasi"
                        v-model="rujukan_regulasi"
                        required
                        name="regulasi"
                        placeholder="Regulasi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-b-tooltip.hover.top="'Upload File Regulasi Lain yang mengatur penugasan'"
                    label="Upload File"
                    label-for="rujukan_file"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="File Dokumen Rujukan Lainnya"
                      rules="required"
                    >
                      <b-form-file
                        ref="rujukan_file"
                        v-model="rujukan_file"
                        name="rujukan_file"
                        required
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </template>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col md="6">
          <p class="mb-50 pb-0">
            Jenis Data yang Dikumpulkan/Dihasilkan
          </p>
          <div class="d-flex justify-content-between">
            <b-form-checkbox
              v-model="jenis_data[0]"
              class="custom-control-primary"
              required
            >
              Data Geospasial
              <b-link
                v-b-tooltip.hover.top="'Unduh Contoh Form Daftar Data Spasial'"
                href="/panduan/form_daftar_data_spasial_clearance_spba_ta_2024.xlsx"
                target="_blank"
                class="ml-25"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
              </b-link>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="jenis_data[1]"
              class="custom-control-primary"
              required
            >
              Data Statistik
              <b-link
                v-b-tooltip.hover.top="'Unduh Contoh Form Daftar Data Statistik'"
                href="/panduan/form_daftar_data_statistik_clearance_spba_ta_2024.xlsx"
                target="_blank"
                class="ml-25"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
              </b-link>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="jenis_data[2]"
              class="custom-control-primary"
              required
            >
              Data Lainnya
              <b-link
                v-b-tooltip.hover.top="'Unduh Contoh Form Daftar Data Lainnya'"
                href="/panduan/form_daftar_data_lainnya_clearance_spba_ta_2024.xlsx"
                target="_blank"
                class="ml-25"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
              </b-link>
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="jenis_data[0]"
        class="mb-2"
      >
        <b-col cols="12">
          <hr>
          <h5 class="my-1">
            Belanja Data Geospasial
          </h5>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            label="Kategori Jenis Belanja Data Spasial"
            label-for="vi-jenis_data_spasial"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Jenis Belanja Data Spasial"
              rules="required"
            >
              <v-select
                id="vi-jenis_data_spasial"
                v-model="kategoriJenisData[0]"
                required
                name="jenis_data_spasial"
                :options="daftarJenisDataSpasial"
                placeholder="Kategori Jenis Belanja Data Spasial"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Unit yang berperan sebagai produsen Data Spasial (Satuan Kerja Eselon II).'"
            label="Produsen Data Spasial (Satuan Kerja Eselon II)"
            label-for="vi-produsen_spasial_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Produsen Data Spasial (Satuan Kerja Eselon II)"
              rules="required"
            >
              <b-form-input
                id="vi-produsen_spasial_1"
                v-model="produsen[0]"
                required
                name="produsen_spasial_1"
                placeholder="Produsen Data Spasial"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Aplikasi yang berperan sebagai pendukung / pengumpulan Data Spasial.'"
            label="Aplikasi Pendukung/Pengumpulan Data (Spasial)"
            label-for="vi-aplikasi_spasial_1"
          >
            <b-form-input
              id="vi-aplikasi_spasial_1"
              v-model="aplikasi[0]"
              name="aplikasi_spasial_1"
              placeholder="Aplikasi Pendukung/Pengumpulan Data (Spasial)"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
            label="Sifat Data (Spasial)"
            label-for="vi-data_status_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Sifat Data (Spasial)"
              rules="required"
            >
              <v-select
                id="vi-data_status_1"
                v-model="sifat[0]"
                required
                name="data_status_1"
                :options="referenceData.ref_sifat_data"
                placeholder="Sifat Data (Spasial)"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran[0] !== '') ? ': Rp. ' + Number(total_anggaran[0]).toLocaleString() : ''}`"
            label-for="vi-total_anggaran"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran"
                v-model="total_anggaran[0]"
                type="number"
                name="total_anggaran"
                required
                placeholder="Total Anggaran"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf), mohon dilengkapi dengan mekanisme penyelenggaraan IGT dan status pemenuhan SDI'"
            label="Kerangka Acuan Kerja (dalam format PDF)"
            label-for="file_kak_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Kerangka Acuan Kerja Belanja Spasial"
              rules="required"
            >
              <b-form-file
                ref="file_kak_1"
                v-model="file2[0]"
                name="file_kak_1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload Daftar Data Spasial (dalam format .xls atau .csv)'"
            label="Upload Daftar Data Spasial (dalam format .xls atau .csv)"
            label-for="file_1"
          >
            <validation-provider
              #default="{ errors }"
              name="Daftar Data Spasial"
              rules="required"
            >
              <b-form-file
                ref="file_1"
                v-model="file1[0]"
                name="file_1"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-link
              href="/panduan/form_daftar_data_spasial_clearance_spba_ta_2024.xlsx"
              target="_blank"
            >
              <feather-icon
                icon="FileIcon"
                size="14"
              /> Contoh Form Daftar Data Spasial
            </b-link>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="jenis_data[1]"
        class="mb-2"
      >
        <b-col cols="12">
          <hr>
          <h5 class="my-1">
            Belanja Data Statistik
          </h5>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            label="Kategori Jenis Belanja Data Statistik"
            label-for="vi-jenis_data_statistik"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Jenis Belanja Data Statistik"
              rules="required"
            >
              <v-select
                id="vi-jenis_data_statistik"
                v-model="kategoriJenisData[1]"
                required
                name="jenis_data_statistik"
                :options="daftarJenisDataStatistik"
                placeholder="Kategori Jenis Belanja Data Statistik"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Unit yang berperan sebagai produsen Data Statistik (Satuan Kerja Eselon II).'"
            label="Produsen Data Statistik (Satuan Kerja Eselon II)"
            label-for="vi-produsen_spasial_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Produsen Data Statistik (Satuan Kerja Eselon II)"
              rules="required"
            >
              <b-form-input
                id="vi-produsen_spasial_2"
                v-model="produsen[1]"
                required
                name="produsen_spasial_2"
                placeholder="Produsen Data Statistik"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Aplikasi yang berperan sebagai pendukung / pengumpulan Data Statistik.'"
            label="Aplikasi Pendukung/Pengumpulan Data (Statistik)"
            label-for="vi-aplikasi_spasial_2"
          >
            <b-form-input
              id="vi-aplikasi_spasial_2"
              v-model="aplikasi[1]"
              name="aplikasi_spasial_2"
              placeholder="Aplikasi Pendukung/Pengumpulan Data (Statistik)"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
            label="Sifat Data (Statistik)"
            label-for="vi-data_status_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Sifat Data (Statistik)"
              rules="required"
            >
              <v-select
                id="vi-data_status_2"
                v-model="sifat[1]"
                required
                name="data_status_2"
                :options="referenceData.ref_sifat_data"
                placeholder="Sifat Data (Statistik)"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran[1] !== '') ? ': Rp. ' + Number(total_anggaran[1]).toLocaleString() : ''}`"
            label-for="vi-total_anggaran"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran"
                v-model="total_anggaran[1]"
                type="number"
                name="total_anggaran"
                required
                placeholder="Total Anggaran"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf), mohon dilengkapi dengan mekanisme penyelenggaraan IGT dan status pemenuhan SDI'"
            label="Kerangka Acuan Kerja (dalam format PDF)"
            label-for="file_kak_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Kerangka Acuan Kerja Belanja Statistik"
              rules="required"
            >
              <b-form-file
                ref="file_kak_2"
                v-model="file2[1]"
                name="file_kak_2"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6' && kategoriJenisData[1] && kategoriJenisData[1].kategori_jenis_data_id < 6"
          md="12"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Apakah Sudah Memiliki Surat Rekomendasi BPS'"
            label="Sudah Memiliki Surat Rekomendasi BPS?"
            label-for="vi-is_mou"
          >
            <validation-provider
              #default="{ errors }"
              name="Sudah Memiliki Surat Rekomendasi BPS?"
              rules="required"
            >
              <b-form-checkbox
                v-model="is_bps"
                switch
                inline
                class="mt-1"
              /> {{ (is_bps) ? 'Sudah' : 'Belum' }}
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="is_bps && kategoriJenisData[1] && kategoriJenisData[1].kategori_jenis_data_id < 6"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="`Upload file Surat Rekomendasi BPS (dalam format .pdf)`"
            label="Surat Rekomendasi BPS (dalam format PDF)"
            label-for="file_extra_doc"
          >
            <validation-provider
              #default="{ errors }"
              name="Surat Rekomendasi BPS"
              rules="required"
            >
              <b-form-file
                ref="file_extra_doc"
                v-model="file1[1]"
                name="file_extra_doc"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload Daftar Data Statistik (dalam format .xls atau .csv)'"
            label="Upload Daftar Data Statistik (dalam format .xls atau .csv)"
            label-for="file_2"
          >
            <validation-provider
              #default="{ errors }"
              name="Daftar Data Statistik"
              rules="required"
            >
              <b-form-file
                ref="file_2"
                v-model="file1[1]"
                name="file_2"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-link
              href="/panduan/form_daftar_data_statistik_clearance_spba_ta_2024.xlsx"
              target="_blank"
            >
              <feather-icon
                icon="FileIcon"
                size="14"
              /> Contoh Form Daftar Data Statistik
            </b-link>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6' && !is_bps && kategoriJenisData[1] && kategoriJenisData[1].kategori_jenis_data_id < 6"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload Formulir Rekomendasi (dalam format .pdf)'"
            label="Upload Formulir Rekomendasi Statistik (dalam format .pdf)"
            label-for="file_formulir"
          >
            <b-form-file
              ref="file_formulir"
              v-model="file3"
              name="file_formulir"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="jenis_data[2]"
        class="mb-2"
      >
        <b-col cols="12">
          <hr>
          <h5 class="my-1">
            Belanja Data Lainnya (Selain Data Statistik dan Data Geospasial)
          </h5>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            label="Kategori Jenis Belanja Data Lainnya"
            label-for="vi-jenis_data_lainnya"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Jenis Belanja Data Lainnya"
              rules="required"
            >
              <v-select
                id="vi-jenis_data_lainnya"
                v-model="kategoriJenisData[2]"
                required
                name="jenis_data_lainnya"
                :options="daftarJenisDataLainnya"
                placeholder="Kategori Jenis Belanja Data Lainnya"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Unit yang berperan sebagai produsen Data Lainnya (Satuan Kerja Eselon II).'"
            label="Produsen Data Lainnya (Satuan Kerja Eselon II)"
            label-for="vi-produsen_spasial_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Produsen Data Lainnya (Satuan Kerja Eselon II)"
              rules="required"
            >
              <b-form-input
                id="vi-produsen_spasial_3"
                v-model="produsen[2]"
                required
                name="produsen_spasial_3"
                placeholder="Produsen Data Lainnya"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Aplikasi yang berperan sebagai pendukung / pengumpulan Data Lainnya.'"
            label="Aplikasi Pendukung/Pengumpulan Data (Lainnya)"
            label-for="vi-aplikasi_spasial_3"
          >
            <b-form-input
              id="vi-aplikasi_spasial_3"
              v-model="aplikasi[2]"
              name="aplikasi_spasial_3"
              placeholder="Aplikasi Pendukung/Pengumpulan Data (Lainnya)"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Merupakan klasifikasi data berdasarkan bentuk datanya.'"
            label="Sifat Data (Lainnya)"
            label-for="vi-data_status_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Sifat Data (Lainnya)"
              rules="required"
            >
              <v-select
                id="vi-data_status_3"
                v-model="sifat[2]"
                required
                name="data_status_3"
                :options="referenceData.ref_sifat_data"
                placeholder="Sifat Data (Lainnya)"
                label="nama"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
            :label="`Total Anggaran ${(total_anggaran[2] !== '') ? ': Rp. ' + Number(total_anggaran[2]).toLocaleString() : ''}`"
            label-for="vi-total_anggaran"
          >
            <validation-provider
              #default="{ errors }"
              name="Total Anggaran"
              rules="required"
            >
              <b-form-input
                id="vi-total_anggaran"
                v-model="total_anggaran[2]"
                type="number"
                name="total_anggaran"
                required
                placeholder="Total Anggaran"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="jenis_pengadaan_id == '6'"
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf), mohon dilengkapi dengan mekanisme penyelenggaraan IGT dan status pemenuhan SDI'"
            label="Kerangka Acuan Kerja (dalam format PDF)"
            label-for="file_kak_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Kerangka Acuan Kerja Belanja Data Lainnya"
              rules="required"
            >
              <b-form-file
                ref="file_kak_3"
                v-model="file2[2]"
                name="file_kak_3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            v-b-tooltip.hover.top="'Upload Daftar Data Lainnya (dalam format .xls atau .csv)'"
            label="Upload Daftar Data Lainnya (dalam format .xls atau .csv)"
            label-for="file_3"
          >
            <validation-provider
              #default="{ errors }"
              name="Daftar Data Lainnya"
              rules="required"
            >
              <b-form-file
                ref="file_3"
                v-model="file1[2]"
                name="file_3"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-link
              href="/panduan/form_daftar_data_lainnya_clearance_spba_ta_2024.xlsx"
              target="_blank"
            >
              <feather-icon
                icon="FileIcon"
                size="14"
              /> Contoh Form Daftar Data Lainnya
            </b-link>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormDatepicker, BFormGroup, BFormTextarea, BFormCheckbox,
  BInputGroup, BInputGroupAppend, BButton, BAlert, BFormFile, VBTooltip, BLink,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    vSelect,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
    clearanceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      jenis_pengadaan_id: '',
      referenceData: {
        data_informasi_list: [],
        ref_data_pokok: [],
        ref_data_tematik: [],
        ref_data_topic: [],
        ref_sub_data_topic: [],
        ref_sifat_data: [],
        ref_kategori_jenis_data: [],
        ref_eselon_dua: [],
        ref_document_rujukan_data: [],
      },
      data_utama_id: '',
      master_data: '',
      data_informasi_id: '',
      dataData: '',
      data_basic_id: '',
      data_tematic_id: '',
      data_topic_id: '',
      sub_data_topic_id: '',
      nama: '',
      data_interoperability_id: '',
      data_status_id: '',
      tujuan: '',
      valid_start: '',
      valid_end: '',
      pic_name: '',
      deskripsi: '',
      daftarMasterDataInformasi: { data_pokok_id: 'new', label: 'Tambah Data dan Informasi Baru' },
      daftarMasterTopik: { data_topic_id: 'new', nama: 'Tambah Fungsi Pemerintahan Baru' },
      daftarMasterSubTopik: { sub_data_topic_id: 'new', nama: 'Tambah Sub Fungsi Pemerintahan Baru' },
      daftarDataInteroperability: [
        { value: '0', nama: 'Tidak' },
        { value: '1', nama: 'Ya' },
      ],
      daftarDataTematik: [],
      daftarDataTopik: [],
      daftarDataSubTopik: [],
      errorMsg: '',
      walidata: '',
      is_bps: false,
      total_anggaran: [null, null, null],
      file1: [null, null, null],
      file2: [null, null, null],
      file3: null,
      jenis_data: [null, null, null],
      produsen: ['', '', ''],
      aplikasi: ['', '', ''],
      sifat: [null, null, null],
      kategoriJenisData: [null, null, null],
      daftarJenisDataSpasial: [],
      daftarJenisDataStatistik: [],
      daftarJenisDataLainnya: [],
      daftarDataRujukan: [],
      fungsi_and_sub: false,
      sub_fungsi: false,
      data_topic_nama: '',
      sub_data_topic_nama: '',
      rujukan_select: [],
      rujukan_select_dtl: {},
      rujukan_regulasi: '',
      rujukan_file: [],
      rujukan_lainnya: false,
      dokumen_rujukan: [],
    }
  },
  watch: {
    actionStep() {
      this.checkInformasi()
    },
  },
  mounted() {
    this.getReference()
    this.jenis_pengadaan_id = this.$route.params.jenis
    // this.data_informasi_id = 817
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/data-informasi/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.data_informasi_list) {
              const pics = [this.daftarMasterDataInformasi]
              res.data.data.data_informasi_list.map(value => {
                const pic = value
                pic.label = `${value.nama} / ${value.data_pokok} / ${value.data_tematik}`
                pics.push(pic)
                return true
              })
              this.referenceData.data_informasi_list = pics
            }
            if (res.data.data.ref_kategori_jenis_data) {
              res.data.data.ref_kategori_jenis_data.map(value => {
                if (value.jenis_data_id === 1) {
                  this.daftarJenisDataSpasial.push(value)
                }
                if (value.jenis_data_id === 2) {
                  this.daftarJenisDataStatistik.push(value)
                }
                if (value.jenis_data_id === 3) {
                  this.daftarJenisDataLainnya.push(value)
                }
                return true
              })
            }
            if (res.data.data.ref_document_rujukan_data) {
              res.data.data.ref_document_rujukan_data.map(value => {
                this.daftarDataRujukan[value.nama] = value.childreen
                return true
              })
            }
          }
        })
    },
    getDataTematik() {
      this.daftarDataTematik.splice(0)
      this.data_tematic_id = ''

      const filterVal = this.data_basic_id.data_pokok_id
      const refData = this.referenceData.ref_data_tematik
      refData.map(contact => {
        if (contact.data_pokok_id === filterVal) {
          this.daftarDataTematik.push(contact)
        }
        return true
      })
    },
    getDataTopik() {
      this.daftarDataTopik = [this.daftarMasterTopik]
      this.fungsi_and_sub = false
      this.sub_fungsi = false
      this.data_topic_id = ''

      const filterVal = this.data_tematic_id.data_tematik_id
      const refData = this.referenceData.ref_data_topic
      refData.map(contact => {
        if (contact.data_tematik_id === filterVal) {
          this.daftarDataTopik.push(contact)
        }
        return true
      })

      if (this.daftarDataTopik.length === 1) {
        this.fungsi_and_sub = true
        this.sub_fungsi = true
      }
    },
    getDataSubTopik() {
      this.daftarDataSubTopik = [this.daftarMasterSubTopik]
      this.sub_fungsi = false
      this.sub_data_topic_id = ''

      const filterVal = this.data_topic_id.data_topic_id
      const refData = this.referenceData.ref_sub_data_topic
      refData.map(contact => {
        if (contact.data_topic_id === filterVal) {
          this.daftarDataSubTopik.push(contact)
        }
        return true
      })

      if (this.data_topic_id && this.data_topic_id.data_topic_id === 'new') {
        this.fungsi_and_sub = true
      }

      if (this.daftarDataSubTopik.length === 1) {
        this.sub_fungsi = true
      }
    },
    setSubFungsiGov() {
      if (this.sub_data_topic_id && this.sub_data_topic_id.sub_data_topic_id === 'new') {
        this.sub_fungsi = true
      }
    },
    unitReset(val) {
      if (val === 'cat') {
        this.fungsi_and_sub = false
        this.sub_fungsi = false
        this.data_topic_nama = ''
        this.sub_data_topic_nama = ''
      }
      if (val === 'sub') {
        this.sub_fungsi = false
        this.sub_data_topic_nama = ''
      }
    },
    setSelected() {
      if (this.master_data.data_informasi_id !== 'new') {
        this.dataData = this.master_data

        this.nama = this.dataData.nama
        this.tujuan = this.dataData.tujuan
        this.valid_start = this.dataData.valid_start
        this.valid_end = this.dataData.valid_end
        this.pic_name = this.dataData.pic_name
        this.deskripsi = this.dataData.deskripsi
        this.walidata = this.dataData.walidata

        this.data_basic_id = {
          data_pokok_id: this.dataData.data_pokok_id,
          nama: this.dataData.data_pokok,
        }
        this.getDataTematik()

        if (this.dataData.data_tematik_id) {
          this.data_tematic_id = {
            data_tematik_id: this.dataData.data_tematik_id,
            nama: this.dataData.data_tematik,
          }
          this.getDataTopik()
        }

        if (this.dataData.data_topic_id) {
          this.data_topic_id = {
            data_topic_id: this.dataData.data_topic_id,
            nama: this.dataData.data_topic,
          }
          this.getDataSubTopik()
        }

        if (this.dataData.sub_data_topic_id) {
          this.sub_data_topic_id = {
            sub_data_topic_id: this.dataData.sub_data_topic_id,
            nama: this.dataData.sub_data_topic,
          }
        }

        this.data_status_id = {
          sifat_data_id: this.dataData.sifat_data_id,
          nama: this.dataData.sifat_data,
        }

        this.data_interoperability_id = this.daftarDataInteroperability[this.dataData.interoperability_id]
      } else {
        this.dataData = ''

        this.nama = ''
        this.tujuan = ''
        this.valid_start = ''
        this.valid_end = ''
        this.pic_name = ''
        this.deskripsi = ''

        this.data_basic_id = ''
        this.data_tematic_id = ''
        this.data_topic_id = ''
        this.data_status_id = ''
        this.data_interoperability_id = ''
      }
    },
    setRujukan() {
      this.dokumen_rujukan = []
      this.rujukan_lainnya = false
      this.rujukan_select.map(dt => {
        if (dt === 'Lainnya') {
          this.rujukan_lainnya = true
          this.dokumen_rujukan.push(1)
        } else if (this.daftarDataRujukan[dt].length === 1) {
          this.dokumen_rujukan.push(this.daftarDataRujukan[dt][0].dokumen_rujukan_id)
        } else if (this.rujukan_select_dtl[dt]) {
          this.rujukan_select_dtl[dt].map(dp => {
            this.dokumen_rujukan.push(dp.dokumen_rujukan_id)
            return true
          })
        }
        return true
      })
      return true
    },
    checkRujukan() {
      this.setRujukan()
      if (this.rujukan_select.includes('Lainnya') && !this.rujukan_regulasi && this.rujukan_file.length === 0) {
        const data = {
          status: false,
          msg: 'Dokumen Rujukan Lainnya Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
        return false
      } if (this.dokumen_rujukan.length === 0) {
        const data = {
          status: false,
          msg: 'Dokumen Rujukan Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
        return false
      }
      return true
    },
    checkInformasi() {
      if (this.checkRujukan()) {
        let jenisCheck = false

        this.jenis_data.map(dt => {
          if (dt === true) {
            jenisCheck = true
          }
          return true
        })

        if (!jenisCheck && (Number(this.jenis_pengadaan_id) === 3 || Number(this.jenis_pengadaan_id) === 6)) {
          const data = {
            status: false,
            msg: 'Jenis Data yang Dikumpulkan/Dihasilkan Tidak Boleh Kosong',
          }
          this.$emit('step-completed', data)
        } else {
          this.saveInformasi()
        }
      }
    },
    saveInformasi() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        data_utama_id: this.clearanceId,
        instansi_id: this.userData.instansi_id,
        data_pokok_id: this.data_basic_id ? this.data_basic_id.data_pokok_id : 1,
        data_tematik_id: this.data_tematic_id ? this.data_tematic_id.data_tematik_id : 1,
        nama: this.nama,
        interoperability_id: this.data_interoperability_id.value,
        sifat_data_id: this.sifat_data_id ? this.data_status_id.sifat_data_id : 1,
        tujuan: this.tujuan,
        valid_start: this.valid_start,
        valid_end: this.valid_end,
        pic_name: this.pic_name,
        deskripsi: this.deskripsi,
        walidata: this.walidata,
        tahun_anggaran: this.coreData.year_budget,
      }
      if (this.data_informasi_id !== '') {
        metaUtama.data_informasi_id = this.data_informasi_id
      }
      if (this.data_topic_id && this.data_topic_id.data_topic_id !== 'new') {
        metaUtama.data_topic_id = this.data_topic_id.data_topic_id
      }
      if (this.sub_data_topic_id && this.sub_data_topic_id.sub_data_topic_id !== 'new') {
        metaUtama.sub_data_topic_id = this.sub_data_topic_id.sub_data_topic_id
      }
      if (this.data_topic_nama) {
        metaUtama.data_topic_nama = this.data_topic_nama
      }
      if (this.sub_data_topic_nama) {
        metaUtama.sub_data_topic_nama = this.sub_data_topic_nama
      }
      if (this.dokumen_rujukan.length !== 0) {
        metaUtama.dokumen_rujukan = this.dokumen_rujukan
      }
      this.$http.post('/clearance/data-informasi', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.$route.params.id,
          data_utama_id: this.clearanceId,
        },
      })
        .then(res => {
          this.data_informasi_id = res.data.data.data_informasi_id
          if (Number(this.jenis_pengadaan_id) === 3 || Number(this.jenis_pengadaan_id) === 6) {
            if (this.rujukan_lainnya) {
              this.saveRujukanLainnya()
            } else {
              this.saveJenisData()
            }
          } else {
            const data = {
              status: true,
              msg: res.data.data,
            }
            data.msg.data_utama_id = this.clearanceId
            this.$emit('step-completed', data)
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saveJenisData() {
      const formFile = new FormData()
      let c = 0
      let send = true
      this.jenis_data.map((dt, idx) => {
        if (dt === true) {
          formFile.append(`datas[${c}][jenis_data_id]`, idx + 1)
          if (this.sifat[idx] === null) {
            send = false
          } else {
            formFile.append(`datas[${c}][sifat_data_id]`, this.sifat[idx].sifat_data_id)
          }
          if (this.produsen[idx] === null) {
            send = false
          } else {
            formFile.append(`datas[${c}][produsen_data]`, this.produsen[idx])
          }
          formFile.append(`datas[${c}][aplikasi_sumber]`, this.aplikasi[idx])
          formFile.append(`datas[${c}][total_anggaran]`, this.total_anggaran[idx])
          if (this.file1[idx]) {
            formFile.append(`datas[${c}][doc]`, this.file1[idx])
          } else {
            formFile.append(`datas[${c}][doc]`, '')
          }
          if (this.file2[idx]) {
            formFile.append(`datas[${c}][kak]`, this.file2[idx])
          } else {
            formFile.append(`datas[${c}][kak]`, '')
          }
          if (idx === 1 && this.file3) {
            formFile.append(`datas[${c}][formulir]`, this.file3)
          } else {
            formFile.append(`datas[${c}][formulir]`, '')
          }
          if (this.kategoriJenisData[idx] && this.kategoriJenisData[idx].kategori_jenis_data_id) {
            formFile.append(`datas[${c}][kategori_jenis_data_id]`, this.kategoriJenisData[idx].kategori_jenis_data_id)
          }
          c += 1
        }
        return dt
      })

      if (!send) {
        const data = {
          status: false,
          msg: 'Periksa Kelengkapan Isian Jenis Data Anda',
        }
        this.$emit('step-completed', data)
        document.getElementById('loading-bg').style.display = 'none'
      } else if (c !== 0) {
        this.sendJenisData(formFile)
      }
    },
    sendJenisData(formFile) {
      this.$http.post('/clearance/data-informasi/add-item', formFile, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.$route.params.id,
          data_utama_id: this.clearanceId,
          data_informasi_id: this.data_informasi_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          data.msg.data_utama_id = this.clearanceId
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saveRujukanLainnya() {
      const formFile = new FormData()
      formFile.append('data_informasi_id', this.data_informasi_id)
      formFile.append('dokumen_rujukan_id', 1)
      formFile.append('nama_regulasi', this.rujukan_regulasi)
      formFile.append('dokumen_rujukan_file', this.rujukan_file)

      this.$http.post('/clearance/data-informasi/file/upload-dok-rujukan', formFile, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(() => {
          this.saveJenisData()
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
