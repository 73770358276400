var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Metadata Utama "+_vm._s(_vm.sub_title)+" ")])]),(_vm.sipdLoad)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Memuat data "),_c('b',[_vm._v("SIPD")]),_vm._v(", mohon menunggu")])])])],1):_vm._e(),(_vm.sipdFailed)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found with "),_c('b',[_vm._v("SIPD")])]),_c('div',{staticClass:"alert-body"},[(_vm.sipdFailedMsg != '')?_c('span',[_vm._v(" "+_vm._s(_vm.sipdFailedMsg)+" "),(_vm.sipdFailedLink)?_c('b-link',{attrs:{"target":"_blank","href":"https://docs.google.com/forms/d/e/1FAIpQLSfRi98gWYJnGLThDh9AeCVRMhQQq5u_l4aCgKfWvlQ8pFf2ow/viewform"}},[_vm._v("link berikut")]):_vm._e(),_vm._v(". ")],1):_c('span',[_vm._v("Konektivitas dengan "),_c('u',[_vm._v("SIPD")]),_vm._v(" gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi "),_c('u',[_vm._v("Admin")])])])])],1):_vm._e(),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Kriteria Belanja Program sesuai dengan Renja'),expression:"'Kriteria Belanja Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kriteria Belanja","label-for":"vi-master_jenis_pengadaan_id"}},[_c('validation-provider',{attrs:{"name":"Kriteria Belanja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_jenis_pengadaan_id","required":"","name":"master_jenis_pengadaan_id","options":_vm.referenceData.ref_jenis_pengadaan,"placeholder":"Pilih Salah Satu","label":"nama","disabled":""},model:{value:(_vm.master_jenis_pengadaan_id),callback:function ($$v) {_vm.master_jenis_pengadaan_id=$$v},expression:"master_jenis_pengadaan_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Unit Kerja sesuai dengan Renja'),expression:"'Nama Unit Kerja sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Kerja","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Unit Kerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-unit_kerja","required":"","name":"unit_kerja","options":_vm.saktiUnit,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetKegiatan()}},model:{value:(_vm.unit_kerja),callback:function ($$v) {_vm.unit_kerja=$$v},expression:"unit_kerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1209550171)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Unit Kerja sesuai dengan Renja'),expression:"'Nama Unit Kerja sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Unit Kerja","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Nama Unit Kerja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-nama_unit_kerja","required":"","name":"nama_unit_kerja","placeholder":"Unit Kerja (Lengkap Kode dan Nama)","autofocus":(_vm.fokusRo === 'unit') ? true : false},model:{value:(_vm.nama_unit_kerja),callback:function ($$v) {_vm.nama_unit_kerja=$$v},expression:"nama_unit_kerja"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.unitReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Penanggung Jawab sesuai dengan Renja'),expression:"'Nama Penanggung Jawab sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Penanggung Jawab","label-for":"vi-pic_id"}},[_c('validation-provider',{attrs:{"name":"Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-pic_id","required":"","name":"pic_id","options":_vm.referenceData.ref_pic,"label":"label","placeholder":"Pilih Salah Satu atau Tambah Baru"},on:{"input":function($event){return _vm.setContact()}},model:{value:(_vm.penanggungJawabModel),callback:function ($$v) {_vm.penanggungJawabModel=$$v},expression:"penanggungJawabModel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.penanggungJawabModel.pic_id === 'new')?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Penanggung Jawab sesuai dengan Renja'),expression:"'Nama Penanggung Jawab sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Penanggung Jawab","label-for":"vi-pic_name"}},[_c('validation-provider',{attrs:{"name":"Nama Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-pic_name","required":"","name":"pic_name","placeholder":"Nama Penanggung Jawab"},model:{value:(_vm.pic_name),callback:function ($$v) {_vm.pic_name=$$v},expression:"pic_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2775029539)})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nomor Kontak Penanggung Jawab sesuai dengan Renja'),expression:"'Nomor Kontak Penanggung Jawab sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nomor Penanggung Jawab","label-for":"vi-pic_number"}},[_c('validation-provider',{attrs:{"name":"Nomor Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-pic_number","type":"number","required":"","name":"pic_number","placeholder":"Nomor Penanggung Jawab"},model:{value:(_vm.pic_number),callback:function ($$v) {_vm.pic_number=$$v},expression:"pic_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2563879096)})],1)],1)],1)],1):_c('div',[_c('b-form-input',{attrs:{"value":_vm.penanggungJawabModel.name,"hidden":"","name":"pic_name"},model:{value:(_vm.pic_name),callback:function ($$v) {_vm.pic_name=$$v},expression:"pic_name"}}),_c('b-form-input',{attrs:{"value":_vm.penanggungJawabModel.number,"hidden":"","name":"pic_number"},model:{value:(_vm.pic_number),callback:function ($$v) {_vm.pic_number=$$v},expression:"pic_number"}})],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Bidang Pemerintahan Program sesuai dengan Renja'),expression:"'Bidang Pemerintahan Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Bidang Pemerintahan","label-for":"vi-kode_model_referensi"}},[_c('validation-provider',{attrs:{"name":"Nama Bidang Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kode_model_referensi","required":"","name":"kode_model_referensi","options":_vm.saktiKro,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetRo()}},model:{value:(_vm.kode_model_referensi),callback:function ($$v) {_vm.kode_model_referensi=$$v},expression:"kode_model_referensi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1141779679)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Bidang Pemerintahan Program sesuai dengan Renja'),expression:"'Bidang Pemerintahan Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Bidang Pemerintahan","label-for":"vi-kode_model_referensi"}},[_c('validation-provider',{attrs:{"name":"Nama Bidang Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-kode_model_referensi","required":"","name":"kode_model_referensi","placeholder":"Bidang Pemerintahan Lengkap Kode dan Nama","autofocus":(_vm.fokusRo === 'kro') ? true : false},model:{value:(_vm.nama_kode_model_referensi),callback:function ($$v) {_vm.nama_kode_model_referensi=$$v},expression:"nama_kode_model_referensi"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.kroReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-info"},[_vm._v("Pastikan penulisan Nama dan Kode Bidang Pemerintahan telah sesuai dan tepat."),_c('br')]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center"},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('div',{staticClass:"alert-body"},[_c('b',[_vm._v("Nama Program")]),_vm._v(" akan diisikan pada "),_c('b',[_vm._v("Domain Proses Bisnis")])])])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Kegiatan sesuai dengan Renja'),expression:"'Nama Kegiatan sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Kegiatan","label-for":"vi-nama_kegiatan"}},[_c('validation-provider',{attrs:{"name":"Nama Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-nama_kegiatan","required":"","name":"nama_kegiatan","options":_vm.saktiKegiatan,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetKro()}},model:{value:(_vm.nama_kegiatan),callback:function ($$v) {_vm.nama_kegiatan=$$v},expression:"nama_kegiatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1859775619)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama Kegiatan sesuai dengan Renja'),expression:"'Nama Kegiatan sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Kegiatan","label-for":"vi-nama_kegiatan"}},[_c('validation-provider',{attrs:{"name":"Nama Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vi-nama_kegiatan","required":"","name":"nama_kegiatan","placeholder":"Nama Kegiatan (Lengkap Kode dan Nama)","autofocus":(_vm.fokusRo === 'giat') ? true : false},model:{value:(_vm.nama_kegiatan_sendiri),callback:function ($$v) {_vm.nama_kegiatan_sendiri=$$v},expression:"nama_kegiatan_sendiri"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.namaReset}},[_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-info"},[_vm._v("Pastikan penulisan Nama dan Kode Kegiatan telah sesuai dan tepat."),_c('br')]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Sub Kegiatan Program sesuai dengan Renja'),expression:"'Sub Kegiatan Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Sub Kegiatan","label-for":"vi-rincian_output"}},[_c('validation-provider',{attrs:{"name":"Nama Sub Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-rincian_output","required":"","name":"rincian_output","options":_vm.saktiRo,"placeholder":"Pilih Salah Satu","label":"label"},on:{"input":function($event){return _vm.saktiGetItem()}},model:{value:(_vm.rincian_output),callback:function ($$v) {_vm.rincian_output=$$v},expression:"rincian_output"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2371695185)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Sub Kegiatan Program sesuai dengan Renja'),expression:"'Sub Kegiatan Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Sub Kegiatan","label-for":"vi-rincian_output"}},[_c('validation-provider',{attrs:{"name":"Nama Sub Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-rincian_output","required":"","name":"rincian_output","placeholder":"Sub Kegiatan Lengkap Kode dan Nama"},model:{value:(_vm.rincian_output),callback:function ($$v) {_vm.rincian_output=$$v},expression:"rincian_output"}}),_c('small',{staticClass:"text-info"},[_vm._v("Pastikan penulisan Nama dan Kode Sub Kegiatan telah sesuai dan tepat."),_c('br')]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tahun Anggaran Program sesuai dengan Renja'),expression:"'Tahun Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Tahun Anggaran","label-for":"vi-tahun_anggaran"}},[_c('validation-provider',{attrs:{"name":"Tahun Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-tahun_anggaran","type":"number","required":"","name":"tahun_anggaran","placeholder":"Tahun Anggaran","disabled":(_vm.sipdFailed === true) ? false : true},model:{value:(_vm.tahun_anggaran),callback:function ($$v) {_vm.tahun_anggaran=$$v},expression:"tahun_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Total Anggaran Program sesuai dengan Renja'),expression:"'Total Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Total Anggaran " + ((_vm.total_anggaran !== '') ? ': Rp. ' + Number(_vm.total_anggaran).toLocaleString() : '')),"label-for":"vi-total_anggaran"}},[_c('validation-provider',{attrs:{"name":"Total Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-total_anggaran","required":"","name":"total_anggaran","placeholder":"Total Anggaran","disabled":(_vm.sipdFailed === true) ? false : true},model:{value:(_vm.total_anggaran),callback:function ($$v) {_vm.total_anggaran=$$v},expression:"total_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1726175909)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Total Anggaran Program sesuai dengan Renja'),expression:"'Total Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Total Anggaran " + ((_vm.total_anggaran !== '') ? ': Rp. ' + Number(_vm.total_anggaran).toLocaleString() : '')),"label-for":"vi-total_anggaran"}},[_c('validation-provider',{attrs:{"name":"Total Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-total_anggaran","type":"number","required":"","name":"total_anggaran","placeholder":"Total Anggaran"},model:{value:(_vm.total_anggaran),callback:function ($$v) {_vm.total_anggaran=$$v},expression:"total_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Kode Mata Anggaran Program sesuai dengan Renja'),expression:"'Kode Mata Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kode Mata Anggaran","label-for":"vi-kode_anggaran"}},[_c('validation-provider',{attrs:{"name":"Kode Mata Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-kode_anggaran","required":"","name":"kode_anggaran","placeholder":"Kode Mata Anggaran","disabled":(_vm.sipdFailed === true) ? false : true},model:{value:(_vm.kode_anggaran),callback:function ($$v) {_vm.kode_anggaran=$$v},expression:"kode_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,415593820)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Kode Mata Anggaran Program sesuai dengan Renja'),expression:"'Kode Mata Anggaran Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kode Mata Anggaran","label-for":"vi-kode_anggaran"}},[_c('validation-provider',{attrs:{"name":"Kode Mata Anggaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-kode_anggaran","required":"","name":"kode_anggaran","placeholder":"Kode Mata Anggaran"},model:{value:(_vm.kode_anggaran),callback:function ($$v) {_vm.kode_anggaran=$$v},expression:"kode_anggaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('small',{staticClass:"text-info"},[_vm._v("contoh : [Kode Dept].[Kode Unit].[Kode Bidang].[Kode Kegiatan].[Kode Sub Kegiatan]")])],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[(_vm.useSipd)?_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Apakah Program Merupakan Prioritas Belanja Pemda?'),expression:"'Apakah Program Merupakan Prioritas Belanja Pemda?'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Prioritas Belanja Pemda","label-for":"vi-nasional_priority"}},[_c('validation-provider',{attrs:{"name":"Prioritas Belanja Pemda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-nasional_priority","required":"","name":"nasional_priority","options":_vm.daftarNationalPriority,"placeholder":"Pilih Salah Satu","label":"text","disabled":(_vm.sipdFailed === true) ? false : true},model:{value:(_vm.prioritas_nasional),callback:function ($$v) {_vm.prioritas_nasional=$$v},expression:"prioritas_nasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2762083271)})],1):_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Apakah Program Merupakan Prioritas Belanja Pemda?'),expression:"'Apakah Program Merupakan Prioritas Belanja Pemda?'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Prioritas Belanja Pemda","label-for":"vi-prioritas_nasional"}},[_c('validation-provider',{attrs:{"name":"Prioritas Belanja Pemda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-prioritas_nasional","required":"","name":"prioritas_nasional","options":_vm.daftarNationalPriority,"placeholder":"Pilih Salah Satu","label":"text"},model:{value:(_vm.prioritas_nasional),callback:function ($$v) {_vm.prioritas_nasional=$$v},expression:"prioritas_nasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis Belanja Program sesuai dengan Renja'),expression:"'Jenis Belanja Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Belanja","label-for":"vi-master_jenis_belanja_id"}},[_c('validation-provider',{attrs:{"name":"Jenis Belanja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_jenis_belanja_id","required":"","name":"master_jenis_belanja_id","options":_vm.referenceData.ref_jenis_belanja,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.master_jenis_belanja_id),callback:function ($$v) {_vm.master_jenis_belanja_id=$$v},expression:"master_jenis_belanja_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Sumber Dana Program sesuai dengan Renja'),expression:"'Sumber Dana Program sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sumber Dana","label-for":"vi-master_sumber_dana_id"}},[_c('validation-provider',{attrs:{"name":"Sumber Dana","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_sumber_dana_id","required":"","name":"master_sumber_dana_id","options":_vm.referenceData.ref_sumber_dana,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.master_sumber_dana_id),callback:function ($$v) {_vm.master_sumber_dana_id=$$v},expression:"master_sumber_dana_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.master_sumber_dana_id && _vm.master_sumber_dana_id.sumber_dana_id < 3)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Apakah Kegiatan Sudah Memiliki MOU atau Belum'),expression:"'Apakah Kegiatan Sudah Memiliki MOU atau Belum'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sudah Memiliki MOU?","label-for":"vi-is_mou"}},[_c('validation-provider',{attrs:{"name":"Sudah Memiliki MOU?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"switch":"","inline":""},model:{value:(_vm.is_mou),callback:function ($$v) {_vm.is_mou=$$v},expression:"is_mou"}}),_vm._v(" "+_vm._s((_vm.is_mou) ? 'Sudah' : 'Belum')+" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3286104233)})],1)],1),(_vm.is_mou)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Upload file Dokumen Penyerta (dalam format .pdf)'),expression:"'Upload file Dokumen Penyerta (dalam format .pdf)'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Dokumen Penyerta (dalam format PDF)","label-for":"file_mou"}},[_c('validation-provider',{attrs:{"name":"Dokumen Penyerta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file_mou",staticClass:"mt-1",attrs:{"name":"file_mou","required":""},on:{"input":_vm.checkFile},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1154870385)})],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }